import React, { useState, forwardRef } from "react";

import "./WindowRepair.css";
import Rem1 from "../assets/REm1.jpg";
import Rem2 from "../assets/REm2.webp";
import Rem3 from "../assets/REm3.webp";
import Rem4 from "../assets/REm4.webp";
import Rem5 from "../assets/REm5.webp";
const categories = [
  {
    id: 1,
    title: "регулировка пластиковых окон",
    content: (
      <>
        {/* Условия выезда */}
        <div className="conditions">
          <h3>Условия выезда</h3>
          <p>✔️ Стоимость выезда мастера – <b>1 500 руб.</b> (бесплатно при заключении договора).</p>
          <p>✔️ Минимальная сумма заказа – <b style={{ color: "red" }}>3 000 руб.</b></p>
        </div>


        {/* Описание и фото */}
        <div className="description">
          <img src={Rem1} alt="Рамочная москитная сетка" className="main-image" />
          <p>
            Осуществляем регулировку пластиковых окон по цене от 350 руб.<br />
            <strong>Устраняем все виды неполадок </strong>, производим замену фурнитуры и механизмов для ПВХ окон и дверей в Краснодаре и Краснодарском крае.
          </p>

        </div>


        <div className="description1">
          <h2>
            Регулировка пластиковых (ПВХ) окон в Краснодаре
          </h2>
          <p>
            Профилактика и регулировка окон пвх обычно включает в себя следующие процедуры:<br />

            <br />• восстановление геометрии окна;
            <br />• герметизация стыков;
            <br /> • смазка фурнитуры и подвижных частей окна;
            <br />• отладка фурнитуры.
            <br />• После комплексной регулировки окно будет лучше сохранять тепло, повысится комфорт при эксплуатации профиля и окно прослужит гораздо дольше.
          </p>
        </div>

        {/* Особенности, виды полотен, гарантии */}
        <div className="features">
          <div className="feature-box">
            <h4>Неисправность</h4>
            <p>Зачем выполнять регулировку? <br />
              <br />✔ створка заедает, клинит
              <br />✔ сквозняки, скрипит
              <br />✔ издает посторонние звуки
              <br />✔ доносится шум с улицы</p>
          </div>
          <div className="feature-box">
            <h4>Виды регулировки</h4>
            <p>✔ Простая (без разборки рамы)   </p>
            <p>✔ Сложная (расклинивание стеклопакетом)</p>

          </div>
          <div className="feature-box">
            <h4>Длительность работ               </h4>
            <p>✔ 1 выезд</p>
            <p> <strong >Время, затрачиваемое на 1 створку:</strong> </p>
            <p>✔ Простая регулировка - до 15 минут</p>
            <p>✔ Сложная - до 60 минут</p>

          </div>
          <div className="feature-box">
            <h4>Цена</h4>
            <p>✔ От 350 руб/створка.. Подробнее в прайс-листе ниже.</p>
          </div>



        </div>






        {/* Таблица цен */}
        <h3>Цены на Москитные Сетки</h3>
        <div className="scroll-table">
          <table className="price-table">
            <thead>
              <tr>
                <th>Регулировка окон ПВХ</th>
                <th>Цена, руб./шт.</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Обычная регулировка фурнитуры пластикового окна</td>
                <td>350</td>
              </tr>
              <tr>
                <td>Сложная регулировка пластиковых окон, с демонтажом створки</td>
                <td>1000</td>
              </tr>
              <tr>
                <td>Регулировка окна с расклиниванием стеклопакета в створке</td>
                <td>1000</td>
              </tr>
              <tr>
                <td>Вскрытие заблокированной створки</td>
                <td>500</td>
              </tr>
              <tr>
                <td>Регулировка окна с ручным дистанционным управлением</td>
                <td>1200</td>
              </tr>
            </tbody>
          </table>
        </div>


        <h3>Как заказать </h3>
        <ul className="order-steps">

          {/* Как заказать */}
          <li>Выбрать тип сетки</li>
          <li>Снять замеры или вызвать мастера</li>
          <li>Связаться с нами: <b>+7 (965) 475-77-88</b></li>
          <li>Оформить заказ и согласовать стоимость</li>
        </ul>
        <div className="whyNeed">
          <h3> Когда нужна регулировка окон</h3>
          <h4>Ремонт и регулировка окон ПВХ может потребоваться в следующих случаях:</h4>
          <p>
            <br />• непрофессиональный монтаж;
            <br />• износ фурнитуры, механизмов и других элементов;
            <br />• несоблюдение правил эксплуатации;
            <br /> • перекос створки;
            <br /> • расшатывание и заклинивание ручки;
            <br /> • провисание петель;
            <br />• продувание из окна;
            <br /> • переход на зимний режим.

          </p>









        </div>

      </>
    ),
  },
  {
    id: 2, title: "Замена уплотнителя",
    content: (
      <>
        {/* Условия выезда */}
        <div className="conditions">
          <h3>Условия выезда</h3>
          <p>✔️ Стоимость выезда мастера – <b>1 500 руб.</b> (бесплатно при заключении договора).</p>
          <p>✔️ Минимальная сумма заказа – <b style={{ color: "red" }}>3 000 руб.</b></p>
        </div>


        {/* Описание и фото */}
        <div className="description">
          <img src={Rem2} alt="Рамочная москитная сетка" className="main-image" />
          <p>Производим замену уплотнителя в пластиковых оконных и дверных системах по цене от 110 рублей за погонный метр.<br /> Предоставляем гарантию на работу 1 год, на материал до 10 лет.
            <strong>Устраняем все виды неполадок </strong>, производим замену фурнитуры и механизмов для ПВХ окон и дверей в Краснодаре и Краснодарском крае.
          </p>

        </div>


        <div className="description1">
          <h2>
            Замена уплотнителя окон ПВХ в Краснодаре
          </h2>
          <p>
            Уплотнитель является важным элементом оконной системы. Именно он обеспечивает плотное прилегание створки к раме окна, обеспечивая тем самым герметичность, шумоизоляцию и теплосбережение.<br />
            Но лучше деать всё сразу
            <br />• восстановление геометрии окна;
            <br />• герметизация стыков;
            <br />• смазка фурнитуры и подвижных частей окна;
            <br />• отладка фурнитуры.
            <br />• После комплексной регулировки окно будет лучше сохранять тепло, повысится комфорт при эксплуатации профиля и окно прослужит гораздо дольше.
          </p>
        </div>

        {/* Особенности, виды полотен, гарантии */}
        <div className="features">
          <div className="feature-box">
            <h4>Неисправность</h4>
            <p>Изношенный уплотнитель приводит к проблемам:
              <br />
              <br />✔ продувание, сквозняки
              <br />✔ промерзание, заледенение окна
              <br />✔ появление пыли на подоконнике
              <br />✔ посторонние шумы, и нарушенная шумоизоляция
              <br />✔ образование конденсата, запотевание</p>
          </div>
          <div className="feature-box">
            <h4>Материал</h4>
            <p>✔ Мы используем качественную уплотнительную резину: </p>
            <p>Материал изготовления:</p>
            <p>✔EPDM</p>
            <p>✔TPE</p>
            <p>✔PVC</p>
            <p>Cтрана производства:</p>
            <p>✔ Россия</p>
            <p>✔ Германия</p>

          </div>
          <div className="feature-box">
            <h4>Длительность работ               </h4>
            <p>✔ 1 выезд</p>
            <p> <strong >Время, затрачиваемое на 1 створку:</strong> </p>
            <p>✔ 15 - 30 минут</p>


          </div>
          <div className="feature-box">
            <h4>Цена</h4>
            <p>✔ От 110 до 200 руб/п.м. Зависит от используемого уплотнителя.              Подробнее в прайс-листе ниже..</p>
          </div>



        </div>






        {/* Таблица цен */}
        < h3 > Цены на Москитные Сетки</h3 >
        <div className="scroll-table">

          <table className="price-table">
            <thead>
              <tr>
                <th>Количество, шт</th>
                <th>Цена (руб.) с замером</th>
                <th>Цена (руб.) самовывоз</th>
              </tr>
            </thead>
            <tbody>
              {[1, 2, 3, 4, 5, 6].map((num) => (
                <tr key={num}>
                  <td>{num}</td>
                  <td>{num * 1800}</td>
                  <td>{num * 1600}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>




        <h3>Как заказать </h3>
        <ul className="order-steps">

          {/* Как заказать */}
          <li>Выбрать тип сетки</li>
          <li>Снять замеры или вызвать мастера</li>
          <li>Связаться с нами: <b>+7 (965) 475-77-88</b></li>
          <li>Оформить заказ и согласовать стоимость</li>
        </ul>
        <div className="whyNeed">
          <h3>Когда нужно менять уплотнитель</h3>
          <h4>Ремонт и регулировка окон ПВХ может потребоваться в следующих случаях:</h4>
          <p>
            <br />• Срок службы оконной системы составляет 40-50 лет, в то время как эффективная работа уплотнителя длится не более 10 лет в зависимости от качества материала. Например поливинилхлоридный 3 года, ТРЕ 10 лет, EPDM 10 лет.
            <br />• Чтобы продлить срок службы уплотнителя, необходимо раз в год обрабатывать его специальной силиконовой смазкой. Без должного ухода, со временем, уплотнитель изнашивается, становится твердым и перестает выполнять свои функции. Уплотнитель одинаково быстро изнашивается и в пластиковом, и в алюминиевом, и в деревянном окне.






          </p>
        </div>

      </>
    ),
  },
  {
    id: 3, title: "Изготовление и замена стеклопакета",
    content: (
      <>
        {/* Условия выезда */}
        <div className="conditions">
          <h3>Условия выезда</h3>
          <p>✔️ Стоимость выезда мастера – <b>1 500 руб.</b> (бесплатно при заключении договора).</p>
          <p>✔️ Минимальная сумма заказа – <b style={{ color: "red" }}>3 000 руб.</b></p>
        </div>

        {/* Описание и фото */}
        <div className="description">
          <img src={Rem3} alt="Рамочная москитная сетка" className="main-image" />
          <p>
            выполняет ремонт и замену разбитых стеклопакетов в Краснодаре и Краснодарском крае по цене от 2500 рублей за кв.м.<br />
            <strong>Мы меняем стеклопакеты в пластиковых </strong> деревянных и алюминиевых окнах. Гарантия на все виды работ составляет один год.
          </p>
        </div>

        <div className="description1">
          <h2>
            Замена стеклопакета в Краснодаре
          </h2>
          <p>
            Профилактика и регулировка окон пвх обычно включает в себя следующие процедуры:<br />
            <br />• восстановление геометрии окна;
            <br />• герметизация стыков;
            <br /> • смазка фурнитуры и подвижных частей окна;
            <br />• отладка фурнитуры.
            <br />• После комплексной регулировки окно будет лучше сохранять тепло, повысится комфорт при эксплуатации профиля и окно прослужит гораздо дольше.
          </p>
        </div>

        {/* Особенности, виды полотен, гарантии */}
        <div className="features">
          <div className="feature-box">
            <h4>Неисправность</h4>
            <p>Зачем менять стеклопакет?
              <br />✔ лопнул из-за нарушений при монтаже
              <br />✔ кто-то разбил
              <br />✔ начал запотевать
              <br />✔ плохая шумоизоляция
              <br />✔ недостаточная теплоизоляция
            </p>
          </div>
          <div className="feature-box">
            <h4>Виды стеклопакетов</h4>
            <p>Мы устанавливаем</p>
            <p>✔ 1-, 2- и 3-камерные</p>
            <p>✔ энергосберегающие</p>
            <p>✔ мультифункциональные</p>
            <p>✔ шумоизоляционные</p>
            <p>✔ тонированные</p>
          </div>
          <div className="feature-box">
            <h4>Длительность работ    </h4>
            <p>✔ 2 выезда</p>
            <p> <strong >Время, затрачиваемое на 1 створку </strong> </p>
            <p>✔ ~ 15-60 минут</p>
          </div>
          <div className="feature-box">
            <h4>Цена</h4>
            <p>✔ От 2500 руб/створка.. Подробнее в прайс-листе ниже.</p>
          </div>
        </div>
        {/* Таблица цен */}
        <h3>Цены на Москитные Сетки</h3>
        <div className="scroll-table">
          <table className="price-table">
            <thead>
              <tr>
                <th>Количество, шт</th>
                <th>Цена (руб.) с замером</th>
                <th>Цена (руб.) самовывоз</th>
              </tr>
            </thead>
            <tbody>
              {[1, 2, 3, 4, 5, 6].map((num) => (
                <tr key={num}>
                  <td>{num}</td>
                  <td>{num * 1800}</td>
                  <td>{num * 1600}</td>
                </tr>
              ))}
            </tbody>
          </table> </div>

        <h3>Как заказать </h3>
        <ul className="order-steps">
          {/* Как заказать */}
          <li>Выбрать тип сетки</li>
          <li>Снять замеры или вызвать мастера</li>
          <li>Связаться с нами: <b>+7 (965) 475-77-88</b></li>
          <li>Оформить заказ и согласовать стоимость</li>
        </ul>
        <div className="whyNeed">
          <h3> Когда нужна регулировка окон</h3>
          <h4>Ремонт и регулировка окон ПВХ может потребоваться в следующих случаях:</h4>
          <p>
            <br />• непрофессиональный монтаж;
            <br />• износ фурнитуры, механизмов и других элементов;
            <br />• несоблюдение правил эксплуатации;
            <br />• перекос створки;
            <br />• расшатывание и заклинивание ручки;
            <br />• провисание петель;
            <br />• продувание из окна;
            <br />• переход на зимний режим.
          </p>
        </div>
      </>
    ),
  },
  {
    id: 4, title: "Монтаж откосов и подоконников",
    content: (
      <>
        {/* Условия выезда */}
        <div className="conditions">

          <h3>Условия выезда</h3>
          <p>✔️ Стоимость выезда мастера – <b>1 500 руб.</b> (бесплатно при заключении договора).</p>
          <p>✔️ Минимальная сумма заказа – <b style={{ color: "red" }}>3 000 руб.</b></p>
        </div>

        {/* Описание и фото */}
        <div className="description">
          <img src={Rem4} alt="Рамочная москитная сетка" className="main-image" />

          <p>Перед началом работ важно определиться, какой именно материал Вы хотели бы видеть под окном.
            От выбранного варианта зависит сложность и характер монтажных работ, а также стоимость услуги.<br />
            <strong>Мы меняем стеклопакеты в пластиковых </strong> деревянных и алюминиевых окнах. Гарантия на все виды работ составляет один год.
          </p>
        </div>

        <div className="description1">
          <h2>Установка подоконников и откосов</h2>
          <p>
            Этапы работ и как заказать<br />
            <br />• Проведение замера и подготовка подоконника в соответствии с полученными размерами.;
            <br />• Подготовка оконного проема: выравнивание поверхности, срезание штроб в боковых стенках для монтажа, уборка мусора.;
            <br /> • Установка, закрепление, обработка швов и стыков;
            <br />• От качества монтажа и соблюдения ряда правил зависит дальнейшая служба окна. Неправильно установленный подоконник нарушит геометрию окна и принесёт много других неприятностей..

          </p>
        </div>

        {/* Особенности, виды полотен, гарантии */}
        <div className="features">
          <div className="feature-box">
            <h4>Материалы для подоконника</h4>
            <p>Натуральное дерево
              <br />✔ При правильной обработке поверхность не боится влаги, служит очень долго, переносит перепады температур и отлично вписывается в различные интерьеры.
            </p>
          </div>
          <div className="feature-box">
            <h4>Подоконники из ДСП или МДФ </h4>
            <p>переносят высокие температуры, не нуждаются в особом уходе и имеют широкий спектр окрасок и фактур.</p>
          </div>
          <div className="feature-box">
            <h4>ПВХ </h4>
            <p>наиболее распространенный вариант выбора. Он не боится солнечного света, перепадов температур, влаги, обладает огнестойкостью и увеличенным сроком службы.</p>
          </div>
          <div className="feature-box">
            <h4>Натуральный камень</h4>
            <p>мрамор, оникс и гранит. Популярны среди ВИП заказов. Цена на их установку выше средней, но цель оправдывает средства.</p>
          </div>
        </div>
        {/* Таблица цен */}

        <h3>Стоимость работ</h3>
        <div className="scroll-table">


          <table className="price-table">
            <thead>
              <tr>
                <th>Услуга</th>
                <th>Цена</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Монтаж подоконника от 10 см до 30 см</td>
                <td>945 руб/п.м.</td>
              </tr>
              <tr>
                <td>Монтаж подоконника от 35 см до 50 см</td>
                <td>1135 руб/п.м.</td>
              </tr>
              <tr>
                <td>Монтаж подоконника от 55 см до 70 см</td>
                <td>1325 руб/п.м.</td>
              </tr>
              <tr>
                <td>Монтаж эркерных подоконников</td>
                <td>1325 руб/п.м.</td>
              </tr>
              <tr>
                <td>Монтаж подоконников к алюминиевым окнам (стыковка к раме, без подставочного профиля)</td>
                <td>1420 руб/п.м.</td>
              </tr>
              <tr>
                <td>Создание вентиляционных отверстий (круглой формы) и установка решетки</td>
                <td>285 руб./штука</td>
              </tr>
              <tr>
                <td>Создание вентиляционных отверстий (прямоугольной формы) и установка решетки</td>
                <td>475 руб./штука</td>
              </tr>
              <tr>
                <td>Монтаж вентиляционной решетки алюминиевой белой 80см × 8см</td>
                <td>1700 руб./штука</td>
              </tr>
            </tbody>
          </table>

        </div>


        <h3>Как заказать </h3>
        <ul className="order-steps">
          {/* Как заказать */}
          <li>Снять замеры или вызвать мастера</li>
          <li>Связаться с нами: <b>+7 (965) 475-77-88</b></li>
          <li>Оформить заказ и согласовать стоимость</li>
        </ul>
        <div className="whyNeed">
          <h3>Сколько стоит установить откосы</h3>
          <h4>У нас представлен широкий выбор на любой вкус и кошелек. С примерными ценами можно ознакомиться ниже:</h4>
          <h3>Откосы стандарт</h3>
          <div className="scroll-table">
          <table className="price-table">
            <thead>
              <tr>
                <th>Наименование</th>
                <th>Ширина (мм)</th>
                <th>Единица изм.</th>
                <th>Цена руб. с установкой</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Откосы сэндвич панель</td>
                <td>100</td>
                <td>п/м</td>
                <td>490</td>
              </tr>
              <tr>
                <td>Откосы сэндвич панель</td>
                <td>150</td>
                <td>п/м</td>
                <td>550</td>
              </tr>
              <tr>
                <td>Откосы сэндвич панель</td>
                <td>200</td>
                <td>п/м</td>
                <td>600</td>
              </tr>
              <tr>
                <td>Откосы сэндвич панель</td>
                <td>250</td>
                <td>п/м</td>
                <td>650</td>
              </tr>
              <tr>
                <td>Откосы сэндвич панель</td>
                <td>300</td>
                <td>п/м</td>
                <td>700</td>
              </tr>
              <tr>
                <td>Откосы сэндвич панель</td>
                <td>350</td>
                <td>п/м</td>
                <td>750</td>
              </tr>
              <tr>
                <td>Откосы сэндвич панель</td>
                <td>400</td>
                <td>п/м</td>
                <td>800</td>
              </tr>
              <tr>
                <td>Откосы сэндвич панель</td>
                <td>450 и свыше</td>
                <td>п/м</td>
                <td>договорная</td>
              </tr>
              <tr>
                <td>Откосы с гибким наличником</td>
                <td>250</td>
                <td>п/м</td>
                <td>700</td>
              </tr>
              <tr>
                <td>Откосы с гибким наличником</td>
                <td>400</td>
                <td>п/м</td>
                <td>800</td>
              </tr>
              <tr>
                <td>Наружные откосы ПВХ</td>
                <td>—</td>
                <td>п/м</td>
                <td>850–1150</td>
              </tr>
            </tbody>
          </table>
          </div>
          <h4>Внутренние откосы</h4>
          <p>С внутренней стороны окна устанавливаются откосы из ПВХ или сэндвич панелей.
            <br />• Пластиковые панели естественным образом сочетаются с внешним видом оконного профиля. При желании этот материал можно покрыть ламинирующей декоративной пленкой любого цвета. ПВХ-откосы легко протираются. Панели обеспечивают дополнительную теплоизоляцию окна.
            <br />• Сэндвич-панели. Такой способ установки решает множество проблем, связанных с продуванием, промерзанием, шумоизоляцией и появлением конденсата на окнах. Откосы из сэндвич-панелей также выполняют эстетическую задачу: окно с откосами выглядит одним целым, материал имеет длительный срок службы, панели не плесневеют и легки в уборке.;
          </p>
          <h4>Наружные откосы</h4>
          <p>Внешние откосы монтируются из ПВХ панелей. Пластик не разрушается под действием влаги и ультрафиолета. Небольшой вес и толщина панелей не нарушает конструкцию стены. Монтаж происходит быстро и без мусора.
            <br /> При использовании пластика низкого качества откосы со временем могут пожелтеть. Под воздействием низких температур дешевый материал становится хрупким.
            <br />  Своевременная установка наружных откосов защищает монтажный шов от разрушения, предотвращая попадание влаги внутрь помещения.</p>
        </div>
      </>
    ),
  },
  {
    id: 5, title: "Установка и замена окон в новостройках",
    content: (
      <>
        {/* Условия выезда */}
        <div className="conditions">

          <h3>Условия выезда</h3>
          <p>✔️ Стоимость выезда мастера – <b>1 500 руб.</b> (бесплатно при заключении договора).</p>
          <p>✔️ Минимальная сумма заказа – <b style={{ color: "red" }}>3 000 руб.</b></p>
        </div>

        {/* Описание и фото */}
        <div className="description">
          <img src={Rem5} alt="Рамочная москитная сетка" className="main-image" />

          <p>Покупка новенькой квартиры всегда сопровождается трепетным чувством радости, которое, к сожалению, всегда омрачает состояние окон.<br />
            Окна для застройщика – белый флаг, знаменующий начало продаж, не больше.<br />
            И, конечно, недобросовестных компаний (примерно 90% от общего числа) не волнует вопрос качества, только экономия.<br />

          </p>
        </div>

        <div className="description1">
          <h2>Установка и замена окон в новостройках</h2>
          <p>

            Какие окна предоставляются покупателям новостроек?<br />
            <strong> <br />• Бюджетный тонкий профиль класса В, не армированный или армированный частично, с минимальным числом камер.;</strong>
            <strong><br />• Китайская или турецкая фурнитура низкого качества;</strong>
            <strong><br /> • Тонкий и хрупкий стеклопакет.;</strong>
            <strong><br />• Кривой и еле прилегающий уплотнитель.</strong>
            <strong><br />• В половине случаев завершается это «великолепие» щелями между стеной и профилем.</strong>
            <br />Даже не стоит объяснять, что служить эти системы будут также плохо, как их монтировали. Но допустим, что Вам повезло и застройщик установил окна без внешних огрехов. По негласному правилу, окна в новостройках имеют только однокамерный стеклопакет.
            <br />Такие окна хоть и будут выглядеть сносно, но не смогут выполнять свои функции полностью: постоянное образование конденсата, продувания, проникновение шума с улицы.
          </p>
        </div>

        {/* Особенности, виды полотен, гарантии */}
        {/* <div className="features">
          <div className="feature-box">
            <h4>8 причин порекомендовать нас знакомым</h4>
            <p>Натуральное дерево
              <br />✔ При правильной обработке поверхность не боится влаги, служит очень долго, переносит перепады температур и отлично вписывается в различные интерьеры.
            </p>
          </div>
          <div className="feature-box">
            <h4>Подоконники из ДСП или МДФ </h4>
            <p>переносят высокие температуры, не нуждаются в особом уходе и имеют широкий спектр окрасок и фактур.</p>
          </div>
          <div className="feature-box">
            <h4>ПВХ </h4>
            <p>наиболее распространенный вариант выбора. Он не боится солнечного света, перепадов температур, влаги, обладает огнестойкостью и увеличенным сроком службы.</p>
          </div>
          <div className="feature-box">
            <h4>Натуральный камень</h4>
            <p>мрамор, оникс и гранит. Популярны среди ВИП заказов. Цена на их установку выше средней, но цель оправдывает средства.</p>
          </div>
        </div> */}
        {/* Таблица цен */}

        <h3>Стоимость работ</h3>
        <h3>Цены на популярные услуги</h3>
        <div className="scroll-table">

          <table className="price-table">
            <thead>
              <tr>
                <th>Наименование</th>
                <th>Ед. изм</th>
                <th>Цена, руб</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Регулировка металлопластиковой створки окна</td>
                <td>услуга</td>
                <td>250</td>
              </tr>
              <tr>
                <td>Регулировка балконной двери</td>
                <td>услуга</td>
                <td>от 300</td>
              </tr>
              <tr>
                <td>Замена уплотнителя</td>
                <td>п. м</td>
                <td>126</td>
              </tr>
              <tr>
                <td>Замена штапика</td>
                <td>п. м</td>
                <td>210</td>
              </tr>
              <tr>
                <td>Ремонт ручки</td>
                <td>шт</td>
                <td>250</td>
              </tr>
              <tr>
                <td>Установка гребёнки</td>
                <td>шт</td>
                <td>250</td>
              </tr>
              <tr>
                <td>Установка створки, вместо глухого стеклопакета</td>
                <td>шт</td>
                <td>11000</td>
              </tr>
              <tr>
                <td>Перемонтаж окна</td>
                <td>м²</td>
                <td>2000</td>
              </tr>
              <tr>
                <td>Откосы</td>
                <td>п/м</td>
                <td>от 640</td>
              </tr>
              <tr>
                <td>Подоконники</td>
                <td>п/м</td>
                <td>от 1500</td>
              </tr>
              <tr>
                <td>Отливы</td>
                <td>п/м</td>
                <td>от 1200</td>
              </tr>
              <tr>
                <td>Герметизация внешнего шва</td>
                <td>п/м</td>
                <td>455</td>
              </tr>
              <tr>
                <td>Герметизация козырька</td>
                <td>п/м</td>
                <td>910</td>
              </tr>
            </tbody>
          </table>
        </div>



        <h3>Как заказать </h3>
        <ul className="order-steps">
          {/* Как заказать */}
          <li>Снять замеры или вызвать мастера</li>
          <li>Связаться с нами: <b>+7 (965) 475-77-88</b></li>
          <li>Оформить заказ и согласовать стоимость</li>
        </ul>
        <div className="whyNeed">
          <h4>У нас представлен широкий выбор на любой вкус и кошелек. С примерными ценами можно ознакомиться ниже:</h4>
          <h3>Откосы стандарт</h3>
          <table className="price-table">
            <thead>
              <tr>
                <th>Наименование</th>
                <th>Ширина (мм)</th>
                <th>Единица изм.</th>
                <th>Цена руб. с установкой</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Откосы сэндвич панель</td>
                <td>100</td>
                <td>п/м</td>
                <td>490</td>
              </tr>
              <tr>
                <td>Откосы сэндвич панель</td>
                <td>150</td>
                <td>п/м</td>
                <td>550</td>
              </tr>
              <tr>
                <td>Откосы сэндвич панель</td>
                <td>200</td>
                <td>п/м</td>
                <td>600</td>
              </tr>
              <tr>
                <td>Откосы сэндвич панель</td>
                <td>250</td>
                <td>п/м</td>
                <td>650</td>
              </tr>
              <tr>
                <td>Откосы сэндвич панель</td>
                <td>300</td>
                <td>п/м</td>
                <td>700</td>
              </tr>
              <tr>
                <td>Откосы сэндвич панель</td>
                <td>350</td>
                <td>п/м</td>
                <td>750</td>
              </tr>
              <tr>
                <td>Откосы сэндвич панель</td>
                <td>400</td>
                <td>п/м</td>
                <td>800</td>
              </tr>
              <tr>
                <td>Откосы сэндвич панель</td>
                <td>450 и свыше</td>
                <td>п/м</td>
                <td>договорная</td>
              </tr>
              <tr>
                <td>Откосы с гибким наличником</td>
                <td>250</td>
                <td>п/м</td>
                <td>700</td>
              </tr>
              <tr>
                <td>Откосы с гибким наличником</td>
                <td>400</td>
                <td>п/м</td>
                <td>800</td>
              </tr>
              <tr>
                <td>Наружные откосы ПВХ</td>
                <td>—</td>
                <td>п/м</td>
                <td>850–1150</td>
              </tr>
            </tbody>
          </table>
          <h4>Что делать с «новыми» окнами от застройщика?</h4>
          <p>К сожалению, часто приходится полностью заменять оконную конструкцию.
            В этом случае Вы доверяетесь компании, ответственной за результат, и можете быть уверены в его качестве.
            Тем более, «Мастер окон» всегда идет навстречу своим клиентам и предлагает альтернативные решения на любой бюджет.
          </p>
          <h4>Наружные откосы</h4>
          <p>В некоторых случаях есть возможность ограничиться заменой стеклопакета. Это более дешевый вариант, который может улучшить состояние конструкции. Если провести эту процедуру в комплексе с заменой уплотнителя, регулировкой створок и заменой фурнитуры, Вы увидите настоящее волшебство наших мастеров</p>
        </div>
      </>
    ),
  },
  // {
  //   id: 6, title: "замена петель, ручек, гребенок",
  //   content: "Описание регулировки фурнитуры"
  // },



];

const WindowRepair = forwardRef((props, ref) => {
  const [activeCategory, setActiveCategory] = useState(categories[0]);

  return (
    <div ref={ref} className="window-repair" >
      <h2>РЕМОНТ ПЛАСТИКОВЫХ  </h2>
      <div className="content">
        {/* Левый блок - Список категорий */}
        <div className="menu">
          {categories.map((cat) => (
            <div
              key={cat.id}
              className={`menu-item ${activeCategory.id === cat.id ? "active" : ""}`}
              onClick={() => setActiveCategory(cat)}
            >
              {cat.title}
            </div>
          ))}
        </div>

        {/* Правый блок - Контент выбранной категории */}
        <div className="display">{activeCategory.content}</div>
      </div>
    </div>
  );
});

export default WindowRepair;
