import React, { useState, forwardRef } from "react";

import "./MosquitoNets.css";
import mosquitoImage from "../assets/block1.jpg"; // Заменить на реальный путь
import setki from "../assets/VidSetki0.webp";
import setki1 from "../assets/VidSetki1.webp";
import setki2 from "../assets/VidSetki2.webp";
import setki3 from "../assets/VidSetki3.webp";
import plise from "../assets/tip-plisse.webp";
import rulon from "../assets/rulon.webp";
import Razdvig from "../assets/RazDvig.webp";
import Ustanovka from "../assets/ustanovka.webp";
import PliseSetka from "../assets/plisse-setka2.gif";
import Koshka from "../assets/koshka.webp";
import Antipil from "../assets/antipil.webp";
import Antipilca from "../assets/antipilca.webp";
import Ultra from "../assets/ultra.webp";
import Ramochnie from "../assets/ramochnie.webp";
import Dver from "../assets/Dver.jpg";
import Plise from "../assets/Plise.webp";
import Rulon from "../assets/Rulon1.webp";
import Razdr from "../assets/Razdvi.jpg";
import Vstav from "../assets/Vstav.webp";
import Plise1 from "../assets/PLise.jpg";




const categories = [
    {
        id: 1,
        title: "Рамочные ",
        content: (
            <>
                {/* Условия выезда */}
                <div className="conditions">
                    <h3>Условия выезда</h3>
                    <p>✔️ Стоимость выезда мастера – <b>1 500 руб.</b> (бесплатно при заключении договора).</p>
                    <p>✔️ Минимальная сумма заказа – <b style={{ color: "red" }}>3 000 руб.</b></p>
                </div>


                {/* Описание и фото */}
                <div className="description">
                    <img src={Ramochnie} alt="Рамочная москитная сетка" className="main-image" />
                    <p>
                        Мы предлагаем <b>рамочные москитные сетки</b> для всех типов окон.
                        Изготовлены из прочного материала <b>Fiber Glass</b> с высоким сроком службы.
                        Монтаж возможен в любой день, включая выезд мастера на замеры.
                    </p>

                </div>


                <div className="description1">
                    <h4>
                        Особенности конструкции
                    </h4>
                    <p>
                        Рамочная противомоскитная сетка состоит из алюминиевой рамки и натянутого на нее сеточного полотна. Конструкция рамы позволяет устанавливать сетку на пластиковые, деревянные и алюминиевые окна. Каркас выпускается преимущественно белого и коричневого цвета. Изделия больших размеров оснащаются дополнительной планкой жесткости.

                        Москитная сетка на рамке пользуется популярностью благодаря ряду преимуществ:

                        разнообразие способов крепления;
                        простота монтажа и демонтажа;
                        установка на окна любой формы;
                        доступная цена.
                    </p>
                </div>

                {/* Особенности, виды полотен, гарантии */}
                <div className="features">
                    <div className="feature-box">
                        <h4>Особенности</h4>
                        <p>✔ Универсальная конструкция</p>
                        <p>✔ Надежные алюминиевые профили</p>
                        <p>✔ Простота установки и ухода</p>
                    </div>
                    <div className="feature-box">
                        <h4>Оплата и доставка</h4>
                        <p>✔ Минимальная сумма заказа: 1500 руб.</p>
                        <p>✔ Самовывоз и доставка по городу</p>
                        <p>✔ Оплата наличными или переводом</p>
                    </div>
                    <div className="feature-box">
                        <h4>Гарантии</h4>
                        <p>✔ Гарантия 1 год на все виды москитных сеток</p>
                    </div>
                    <div className="feature-box">
                        <h4>Цена</h4>
                        <p>✔ От 1000 руб/кв.м. Подробнее в прайс-листе ниже.</p>
                    </div>



                </div>


                <div className="sectionPolotno">
                    <h4>Виды полотен</h4>
                    <div className="polotno">
                        <div className="polotno1">
                            <img src={setki} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Классическая</h4>
                                <p>Один из самых распространенных вариантов сеток для окон. Надежно защищает дом от насекомых. Легко снимается и ставится. Проста в уходе и обслуживании. Не занимает много места при хранении зимой.</p>
                                <p>от 2000 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki1} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антипыль</h4>
                                <p>Один из самых распространенных вариантов сеток для окон. Надежно защищает дом от насекомых. Легко снимается и ставится. Проста в уходе и обслуживании. Не занимает много места при хранении зимой.</p>
                                <p>от 2000 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki2} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антикошка</h4>
                                <p>Полотно крепится на плунжерное крепление и способно выдерживать вес среднего животного, которое может погнаться за мухой или солнечным зайчиком. Размер ячеек такой сетки 1 на 1 мм, а толщина 0,6 мм.</p>
                                <p>от 2000 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki3} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антипыльца</h4>
                                <p>Сетка «Антипыльца» — настоящая находка для людей, страдающих от аллергии. Минимальные размеры ячеек задерживают не только насекомых, но и пыльцу растений. Сетка надежно фиксируется в раме, которую можно поставить в отдельный профиль.</p>
                                <p>от 2000 руб/м2</p>
                            </div>

                        </div>

                    </div>
                </div>


                {/* Таблица цен */}
                <h3>Цены на Москитные Сетки</h3>
                <div className="scroll-table">  <table className="price-table">
                    <thead>
                        <tr>
                            <th>Количество, шт</th>
                            <th>Цена (руб.) с замером</th>
                            <th>Цена (руб.) самовывоз</th>
                        </tr>
                    </thead>
                    <tbody>
                        {[1, 2, 3, 4, 5, 6].map((num) => (
                            <tr key={num}>
                                <td>{num}</td>
                                <td>{num * 1800}</td>
                                <td>{num * 1600}</td>
                            </tr>
                        ))}
                    </tbody>
                </table></div>




                <h3>Как заказать москитную сетку</h3>
                <ul className="order-steps">

                    {/* Как заказать */}
                    <li>Выбрать тип сетки</li>
                    <li>Снять замеры или вызвать мастера</li>
                    <li>Связаться с нами: <b>+(965) 475-77-88</b></li>
                    <li>Оформить заказ и согласовать стоимость</li>
                </ul>


            </>
        ),
    },
    {
        id: 2,
        title: "Дверные москитные сетки",
        content: (
            <>
                {/* Условия выезда */}
                <div className="conditions">
                    <h3>Условия выезда</h3>
                    <p>✔️ Стоимость выезда мастера – <b>1 500 руб.</b> (бесплатно при заключении договора).</p>
                    <p>✔️ Минимальная сумма заказа – <b style={{ color: "red" }}>3 000 руб.</b></p>
                </div>
                {/* Описание услуги */}
                <div className="description">
                    <img src={Dver} alt="Москитная сетка дверная" className="main-image" />
                    <p>
                        Наша компания предлагает <b>дверные москитные сетки</b>, обеспечивающие надёжную защиту
                        от насекомых и пыли. Изготовлены из прочного алюминиевого профиля и устойчивого полотна.
                        Установка возможна на любые типы дверей: распашные, раздвижные, рулонные.
                    </p>

                </div>
                {/* Виды дверных москитных сеток */}
                <h3>Виды москитных сеток на дверь</h3>
                <div className="door-types">
                    <div className="door-type">
                        <h4>Рамочная распашная москитная сетка</h4>
                        <p>Конструкция работает по принципу распашных дверей. Надёжно защищает от проникновения насекомых.</p>
                    </div>
                    <div className="door-type">
                        <h4>Рулонная москитная сетка</h4>
                        <p>Фиксируется сбоку от двери, при этом не занимает свободное пространство.</p>
                    </div>
                    <div className="door-type">
                        <h4>Раздвижная москитная сетка</h4>
                        <p>Функционирует по принципу дверей-купе, передвигаясь по направляющим.</p>
                    </div>
                    <div className="door-type">
                        <h4>Москитная сетка на магнитах</h4>
                        <p>Позволяет открывать полотно с обеих сторон. Магнитная лента обеспечивает плотное прилегание.</p>
                    </div>
                </div>
                <div className="sectionPolotno">
                    <h4>Виды полотен</h4>
                    <div className="polotno">
                        <div className="polotno1">
                            <img src={setki} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Классическая</h4>
                                <p>Один из самых распространенных вариантов сеток для окон. Надежно защищает дом от насекомых. Легко снимается и ставится. Проста в уходе и обслуживании. Не занимает много места при хранении зимой.</p>
                                <p>от 2000 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki1} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антипыль</h4>
                                <p>Один из самых распространенных вариантов сеток для окон. Надежно защищает дом от насекомых. Легко снимается и ставится. Проста в уходе и обслуживании. Не занимает много места при хранении зимой.</p>
                                <p>от 2000 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki2} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антикошка</h4>
                                <p>Полотно крепится на плунжерное крепление и способно выдерживать вес среднего животного, которое может погнаться за мухой или солнечным зайчиком. Размер ячеек такой сетки 1 на 1 мм, а толщина 0,6 мм.</p>
                                <p>от 2000 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki3} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антипыльца</h4>
                                <p>Сетка «Антипыльца» — настоящая находка для людей, страдающих от аллергии. Минимальные размеры ячеек задерживают не только насекомых, но и пыльцу растений. Сетка надежно фиксируется в раме, которую можно поставить в отдельный профиль.</p>
                                <p>от 2000 руб/м2</p>
                            </div>

                        </div>

                    </div>
                </div>
                {/* Особенности, виды полотен, доставка */}
                <div className="features">
                    <div className="feature-box">
                        <h4>Особенности</h4>
                        <p>✔ Укреплённый профиль 42×17 мм</p>
                        <p>✔ Фиксация магнитами при закрывании</p>
                        <p>✔ Двусторонние ручки</p>
                    </div>

                    <div className="feature-box">
                        <h4>Оплата и доставка</h4>
                        <p>✔ Оплата наличными или переводом</p>
                        <p>✔ Возможен самовывоз</p>
                        <p>✔ Доставка по городу 500 руб.</p>
                    </div>
                    <div className="feature-box">
                        <h4>Гарантии</h4>
                        <p>✔ Гарантия 1 год на все виды москитных сеток</p>
                    </div>
                </div>
                {/* Таблица цен */}
                <h3>Цены на дверные москитные сетки</h3>
                <div className="scroll-table">
                    <table className="price-table">
                        <thead>
                            <tr>
                                <th>Количество, шт</th>
                                <th>Цена (руб.) с замером</th>
                                <th>Цена (руб.) самовывоз</th>
                            </tr>
                        </thead>
                        <tbody>
                            {[1, 2, 3, 4, 5].map((num) => (
                                <tr key={num}>
                                    <td>{num}</td>
                                    <td>{num * 2000}</td>
                                    <td>{num * 1800}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table> </div>
                {/* Как заказать */}
                <h3>Как заказать москитную сетку</h3>
                <ul className="order-steps">
                    <li>Выбрать тип сетки</li>
                    <li>Снять замеры или вызвать мастера</li>
                    <li>Связаться с нами: <b>+7 (965) 475-77-88</b></li>
                    <li>Оформить заказ и согласовать стоимость</li>
                </ul>
            </>
        ),
    },



    {
        id: 3,
        title: "Плиссе",
        content: (
            <>
                {/* Условия выезда */}
                <div className="conditions">
                    <h3>Условия выезда</h3>
                    <p>✔️ Стоимость выезда мастера – <b>1 500 руб.</b> (бесплатно при заключении договора).</p>
                    <p>✔️ Минимальная сумма заказа – <b style={{ color: "red" }}>3 000 руб.</b></p>
                </div>
                {/* Описание услуги */}
                <div className="description">
                    <img src={Plise} alt="Москитная сетка" className="main-image" />
                    <p>
                        Предлагаем жителям Краснодара и Краснодарского края изготовление и установку москитных сеток плиссе.
                        <br />
                        • Низкие цены от производителя – 4000 рублей за квадратный метр.<br />
                        • Гарантия 1 год на все виды продукции.<br />
                        • Замер в день обращения.
                    </p>

                </div>





                <div>
                    <h4>Виды плиссе</h4>
                    <p>Конструкция сетки может быть любой на Ваш выбор:<br />

                        • Открывание в горизонтальной плоскости в одну сторону (вправо или влево).
                        • Встречное открывание, которое наиболее выгодно смотрится на двустворчатых дверях.
                        • Открывание в вертикальной плоскости (вверх-вниз). Чаще применяется на мансардных окнах.
                    </p>
                    <img src={plise} alt="Рамочная сетка" className="plise-image" />
                </div>

                {/* Виды дверных москитных сеток */}

                <div className="door-types">
                    <div className="door-type">
                        <h4>Особенности</h4>
                        <p> Москитная сетка "плиссе" открывается и закрывается по принципу "гармошки".
                            Сетка "плиссе" свободно перемещается по направляющим, открывая и закрывая проем.</p>
                    </div>
                    <div className="door-type">
                        <h4>Полотно</h4>
                        <p>
                            Сетка "плиссе" комплектуется стандартным антимоскитным полотном:
                            • размер ячейки 1,0*1,2 мм;
                            • защищает от насекомых и мусора с улицы,
                            • материал - стекловолокно,
                            • цвет сетки - серый.</p>
                    </div>
                    <div className="door-type">
                        <h4>Раздвижная москитная сетка</h4>
                        <p>Оплата и доставка
                            Мы принимаем оплату любым удобным Вам способом. Вы можете заказать у нас доставку и установку москитной сетки на дом.
                            Также возможен самовывоз москитки из нашего офиса по адресу Краснодар, Вишнаковой, 3/5, офис 22 (смотреть на карте)</p>
                    </div>
                    <div className="door-type">
                        <h4>Гарантии</h4>
                        <p>
                            На все виды москитных сеток предоставляем гарантию сроком до 1 года.</p>
                    </div>

                    <div className="door-type">
                        <h4>Цена</h4>
                        <p>
                            От 4000 руб/кв.м.
                            Подробнее в прайс-листе ниже.</p>
                    </div>

                </div>




                <div className="sectionPolotno">
                    <h4>Виды полотен</h4>
                    <div className="polotno">
                        <div className="polotno1">
                            <img src={setki} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Классическая</h4>
                                <p>Один из самых распространенных вариантов сеток для окон. Надежно защищает дом от насекомых. Легко снимается и ставится. Проста в уходе и обслуживании. Не занимает много места при хранении зимой.</p>
                                <p>от 2000 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki1} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антипыль</h4>
                                <p>Один из самых распространенных вариантов сеток для окон. Надежно защищает дом от насекомых. Легко снимается и ставится. Проста в уходе и обслуживании. Не занимает много места при хранении зимой.</p>
                                <p>от 2000 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki2} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антикошка</h4>
                                <p>Полотно крепится на плунжерное крепление и способно выдерживать вес среднего животного, которое может погнаться за мухой или солнечным зайчиком. Размер ячеек такой сетки 1 на 1 мм, а толщина 0,6 мм.</p>
                                <p>от 2000 руб/м2</p>
                            </div>
                        </div>


                    </div>
                </div>

                {/* Таблица цен */}
                <h3>Цены на дверные москитные сетки</h3>
                <div className="scroll-table">
                    <table className="price-table">
                        <thead>
                            <tr>
                                <th>Количество, шт</th>
                                <th>Цена (руб.) с замером</th>
                                <th>Цена (руб.) самовывоз</th>
                            </tr>
                        </thead>
                        <tbody>
                            {[1, 2, 3, 4, 5].map((num) => (
                                <tr key={num}>
                                    <td>{num}</td>
                                    <td>{num * 2000}</td>
                                    <td>{num * 1800}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table> </div>
                {/* Как заказать */}
                <h3>Как заказать москитную сетку</h3>
                <ul className="order-steps">
                    <li>Выбрать тип сетки</li>
                    <li>Снять замеры или вызвать мастера</li>
                    <li>Связаться с нами: <b>+(965) 475-77-88</b></li>
                    <li>Оформить заказ и согласовать стоимость</li>
                </ul>
            </>
        ),
    },




    {
        id: 4,
        title: "Рулонные",
        content: (
            <>
                {/* Условия выезда */}
                <div className="conditions">
                    <h3>Условия выезда</h3>
                    <p>✔️ Стоимость выезда мастера – <b>1 500 руб.</b> (бесплатно при заключении договора).</p>
                    <p>✔️ Минимальная сумма заказа – <b style={{ color: "red" }}>3 000 руб.</b></p>
                </div>


                {/* Описание и фото */}
                <div className="description">
                    <img src={Rulon} alt="Рулонная москитная сетка" className="main-image" />
                    <p>
                        Мы предлагаемизготовление и установку рулонных москитных сеток по цене 3150 руб/кв.м в Краснодаре и Краснодарском крае. Конструкция противомоскитной сетки в рулоне позволяет устанавливать изделие на окна любого типа.
                    </p>
                </div>


                <div className="description1">
                    <h4>
                        Как работает рулонная москитка
                    </h4>

                    <div className="descriptoin1_section">

                        <p>
                            Одним из основных элементов конструкции роллетной сетки является короб. Корпус короба изготавливается из алюминиевого сплава и окрашивается в любой цвет. Внутри коробки имеется механизм, благодаря которому полотно сворачивается в рулон и разворачивается без особых усилий.
                            В развернутом виде сеточное полотно закрепляется на нужном уровне с помощью механических фиксаторов. Боковые направляющие и опорная планка обеспечивают ровное прилегание сетки с окну. Механизм оборудован щеточными уплотнителями для самоочищения сетки от пыли и мусора.
                            Открыть и закрыть рулонную москитную сетку можно при помощи шнурка и ручки.

                            Особенности рулонной москитной сетки:
                            Многие отдают предпочтение рулонной противомоскитной сетке благодаря следующим преимуществам:

                            <br /> • Нет необходимости снимать сетку на зиму. Алюминиевый короб защищает полотно от повреждений, когда оно в свернутом виде.
                            <br /> • При отсутствии необходимости в использовании полотно сворачивают, а сам короб не портит внешний вид окна.
                            <br />• Быстрая установка (15 минут).
                            <br /> • Длительный срок службы благодаря самоочищающимся щеточным уплотнителям.
                            <br /> • Универсальная конструкция сетки подходит для установки на ПВХ окна, деревянные, алюминиевые светопрозрачные изделия.
                            <br /> • Широкая цветовая палитра профиля.
                            <br /> • Монтаж короба осуществляется над окном, что не препятствует открыванию створок.
                            <br />  • Простота ухода.
                        </p>


                        <img src={rulon} alt="Рамочная сетка" className="plise-image" />
                    </div>



                </div>

                {/* Особенности, виды полотен, гарантии */}
                <div className="features">
                    <div className="feature-box">
                        <h4>Особенности</h4>
                        <p>✔ Рулонная москитная сетка работает по принципу роллетных жалюзи: вверху сетки устанавливается короб, и при помощи специального механизма полотно сворачивается в рулон и хранится в коробе.</p>

                    </div>
                    <div className="feature-box">
                        <h4>Полотно</h4>
                        <p>✔ Сетка комплектуется стандартным антимоскитным полотном:<br />
                            • размер ячейки 1,0*1,2 мм;
                            • защищает от насекомых и мусора с улицы,
                            • материал - стекловолокно,
                            • цвет сетки - серый..</p>
                    </div>


                    <div className="feature-box">
                        <h4>Оплата и доставка</h4>
                        <p>✔ Минимальная сумма заказа: 1500 руб.</p>
                        <p>✔ Мы принимаем оплату любым удобным Вам способом. Вы можете заказать у нас доставку и установку москитной сетки на дом.</p>

                    </div>
                    <div className="feature-box">
                        <h4>Гарантии</h4>
                        <p>✔ Гарантия 1 год на все виды москитных сеток</p>
                    </div>



                </div>


                <div className="sectionPolotno">
                    <h4>Виды полотен</h4>
                    <div className="polotno">
                        <div className="polotno1">
                            <img src={setki} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Классическая</h4>
                                <p>Один из самых распространенных вариантов сеток для окон. Надежно защищает дом от насекомых. Легко снимается и ставится. Проста в уходе и обслуживании. Не занимает много места при хранении зимой.</p>
                                <p>от 2000 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki1} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антипыль</h4>
                                <p>Один из самых распространенных вариантов сеток для окон. Надежно защищает дом от насекомых. Легко снимается и ставится. Проста в уходе и обслуживании. Не занимает много места при хранении зимой.</p>
                                <p>от 2000 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki2} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антикошка</h4>
                                <p>Полотно крепится на плунжерное крепление и способно выдерживать вес среднего животного, которое может погнаться за мухой или солнечным зайчиком. Размер ячеек такой сетки 1 на 1 мм, а толщина 0,6 мм.</p>
                                <p>от 2000 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki3} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антипыльца</h4>
                                <p>Сетка «Антипыльца» — настоящая находка для людей, страдающих от аллергии. Минимальные размеры ячеек задерживают не только насекомых, но и пыльцу растений. Сетка надежно фиксируется в раме, которую можно поставить в отдельный профиль.</p>
                                <p>от 2000 руб/м2</p>
                            </div>

                        </div>

                    </div>
                </div>


                {/* Таблица цен */}
                <h3>Цены на Москитные Сетки</h3>
                <div className="scroll-table">
                    <table className="price-table">
                        <thead>
                            <tr>
                                <th>Количество, шт</th>
                                <th>Цена (руб.) с замером</th>
                                <th>Цена (руб.) самовывоз</th>
                            </tr>
                        </thead>
                        <tbody>
                            {[1, 2, 3, 4, 5, 6].map((num) => (
                                <tr key={num}>
                                    <td>{num}</td>
                                    <td>{num * 1800}</td>
                                    <td>{num * 1600}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>


                </div>
                <h3>Как заказать москитную сетку</h3>
                <ul className="order-steps">

                    {/* Как заказать */}
                    <li>Выбрать тип сетки</li>
                    <li>Снять замеры или вызвать мастера</li>
                    <li>Связаться с нами: <b>+(965) 475-77-88</b></li>
                    <li>Оформить заказ и согласовать стоимость</li>
                </ul>


            </>
        ),
    },
    {
        id: 5, title: "Раздвижные",
        content: (
            <>
                {/* Условия выезда */}
                <div className="conditions">
                    <h3>Условия выезда</h3>
                    <p>✔️ Стоимость выезда мастера – <b>1 500 руб.</b> (бесплатно при заключении договора).</p>
                    <p>✔️ Минимальная сумма заказа – <b style={{ color: "red" }}>3 000 руб.</b></p>
                </div>


                {/* Описание и фото */}
                <div className="description">
                    <img src={Razdr} alt="Раздвижная москитная сетка" className="main-image" />
                    <p>
                        В нашей компании можно заказать раздвижную москитную сетку для окон и дверей балконов, лоджий, террас. Изготавливаем противомоскитные сетки любых размеров по индивидуальному заказу в Краснодаре. Цена раздвижной сетки от 2300 руб.                    </p>
                </div>


                <div className="description1">
                    <h4>
                        Особенности конструкции
                    </h4>

                    <div className="descriptoin1_section">

                        <p>
                            Принцип работы раздвижных москитных сеток основан на перемещении рамки с сеточным полотном вдоль специальных направляющих. При необходимости сетку легко передвигают на место открытого окна.
                            Рамка сетки изготавливается из алюминия. По углам рамка скрепляется внутренними пластиковыми уголками для сохранения геометрии изделия. Москитные сетки на большие окна оснащаются дополнительной планкой жесткости. На рамке установлена ручка, за которую удобно приводить в движение сетку.
                            Направляющие полозья могут быть различной длины. При необходимости устанавливают несколько противомоскитных рамок.
                        </p>


                        <img src={Razdvig} alt="Рамочная сетка" className="plise-image" />
                    </div>



                </div>

                {/* Особенности, виды полотен, гарантии */}
                <div className="features">
                    <div className="feature-box">
                        <h4>Особенности</h4>
                        <p>✔ Раздвижная москитная сетка устанавливается на специальные полозья, которые позволяют сдвигать сетку в любую сторону. Такие сетки часто используют в комплекте с раздвижными оконными рамами.</p>
                    </div>
                    <div className="feature-box">
                        <h4>Полотно</h4>
                        <p>✔ Заполнение сетки может быть выполнено из любого полотна:<br />
                            <br /> • стандарт
                            <br /> • антикошка
                            <br /> • антимошка
                            <br /> • антипыльца
                            <br /> • антипыль
                        </p>
                    </div>


                    <div className="feature-box">
                        <h4>Оплата и доставка</h4>
                        <p>✔ От 2300 руб/кв.м. <br />
                            Подробнее в прайс-листе ниже.</p>
                        <p>✔ Мы принимаем оплату любым удобным Вам способом. Вы можете заказать у нас доставку и установку москитной сетки на дом.</p>

                    </div>
                    <div className="feature-box">
                        <h4>Гарантии</h4>
                        <p>✔ Гарантия 1 год на все виды москитных сеток</p>
                    </div>



                </div>


                <div className="sectionPolotno">
                    <h4>Виды полотен</h4>
                    <div className="polotno">
                        <div className="polotno1">
                            <img src={setki} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Классическая</h4>
                                <p>Один из самых распространенных вариантов сеток для окон. Надежно защищает дом от насекомых. Легко снимается и ставится. Проста в уходе и обслуживании. Не занимает много места при хранении зимой.</p>
                                <p>от 2000 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki1} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антипыль</h4>
                                <p>Один из самых распространенных вариантов сеток для окон. Надежно защищает дом от насекомых. Легко снимается и ставится. Проста в уходе и обслуживании. Не занимает много места при хранении зимой.</p>
                                <p>от 2000 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki2} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антикошка</h4>
                                <p>Полотно крепится на плунжерное крепление и способно выдерживать вес среднего животного, которое может погнаться за мухой или солнечным зайчиком. Размер ячеек такой сетки 1 на 1 мм, а толщина 0,6 мм.</p>
                                <p>от 2000 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki3} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антипыльца</h4>
                                <p>Сетка «Антипыльца» — настоящая находка для людей, страдающих от аллергии. Минимальные размеры ячеек задерживают не только насекомых, но и пыльцу растений. Сетка надежно фиксируется в раме, которую можно поставить в отдельный профиль.</p>
                                <p>от 2000 руб/м2</p>
                            </div>

                        </div>

                    </div>
                </div>


                {/* Таблица цен */}
                <h3>Цены на Москитные Сетки</h3>
                <div className="scroll-table">
                    <table className="price-table">
                        <thead>
                            <tr>
                                <th>Количество, шт</th>
                                <th>Цена (руб.) с замером</th>
                                <th>Цена (руб.) самовывоз</th>
                            </tr>
                        </thead>
                        <tbody>
                            {[1, 2, 3, 4, 5, 6].map((num) => (
                                <tr key={num}>
                                    <td>{num}</td>
                                    <td>{num * 1800}</td>
                                    <td>{num * 1600}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>


                <h3>Как заказать москитную сетку</h3>
                <ul className="order-steps">

                    {/* Как заказать */}
                    <li>Выбрать тип сетки</li>
                    <li>Снять замеры или вызвать мастера</li>
                    <li>Связаться с нами: <b>+(965) 475-77-88</b></li>
                    <li>Оформить заказ и согласовать стоимость</li>
                </ul>


            </>
        ),
    },
    {
        id: 6, title: "Вставные",
        content: (
            <>
                {/* Условия выезда */}
                <div className="conditions">
                    <h3>Условия выезда</h3>
                    <p>✔️ Стоимость выезда мастера – <b>1 500 руб.</b> (бесплатно при заключении договора).</p>
                    <p>✔️ Минимальная сумма заказа – <b style={{ color: "red" }}>3 000 руб.</b></p>
                </div>


                {/* Описание и фото */}
                <div className="description">
                    <img src={Vstav} alt="Москитная сетка" className="main-image" />
                    <p>
                        Предлагаем изготовление вставных москитных сеток с индивидуальными размерами по цене 2500 руб/м2 жителям Краснодара и Краснодарского края.
                    </p>
                </div>


                <div className="description1">
                    <h4>
                        Особенности конструкции
                    </h4>

                    <div className="descriptoin1_section">
                        <h5>Замер</h5>
                        <p>
                            Выполнив замер самостоятельно вы сэкономите свое время, а также время мастера, за что неприменно получите благодарность от нашей компании.
                            Для получения размеров москитной сетки, следует измерить высоту и ширину проёма, по краю уплотнительной резины.
                        </p>
                        <h5>Установка</h5>
                        <p>
                            Установка вставной сетки не требует особых навыков или инструмента, выполняется даже подростком за несколько минут.
                            • Сначала установить верхний край сетки в проём окна. Верхняя часть сетки устанавливается путём надавливания вверх и вперёд.
                            • Нижняя часть устанавливается надавливанием на нижний профиль вверх и вперёд.
                            • Для того чтобы металлические замки встали на место, следует слегка подвигать вперед-назад или вверх-вниз.
                        </p>


                        <img src={Ustanovka} alt="Рамочная сетка" className="plise-image" />
                    </div>



                </div>

                {/* Особенности, виды полотен, гарантии */}
                <div className="features">
                    <div className="feature-box">
                        <h4>Особенности</h4>
                        <p>✔ Раздвижная москитная сетка устанавливается на специальные полозья, которые позволяют сдвигать сетку в любую сторону. Такие сетки часто используют в комплекте с раздвижными оконными рамами.</p>
                    </div>
                    <div className="feature-box">
                        <h4>Полотно</h4>
                        <p>✔ Заполнение сетки может быть выполнено из любого полотна:<br />
                            <br /> • стандарт
                            <br /> • антикошка
                            <br /> • антимошка
                            <br /> • антипыльца
                            <br /> • антипыль
                        </p>
                    </div>


                    <div className="feature-box">
                        <h4>Оплата и доставка</h4>
                        <p>✔ От 2300 руб/кв.м. <br />
                            Подробнее в прайс-листе ниже.</p>
                        <p>✔ Мы принимаем оплату любым удобным Вам способом. Вы можете заказать у нас доставку и установку москитной сетки на дом.</p>

                    </div>
                    <div className="feature-box">
                        <h4>Гарантии</h4>
                        <p>✔ Гарантия 1 год на все виды москитных сеток</p>
                    </div>



                </div>


                <div className="sectionPolotno">
                    <h4>Виды полотен</h4>
                    <div className="polotno">
                        <div className="polotno1">
                            <img src={setki} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Классическая</h4>
                                <p>Один из самых распространенных вариантов сеток для окон. Надежно защищает дом от насекомых. Легко снимается и ставится. Проста в уходе и обслуживании. Не занимает много места при хранении зимой.</p>
                                <p>от 2500 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki1} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антипыль</h4>
                                <p>Один из самых распространенных вариантов сеток для окон. Надежно защищает дом от насекомых. Легко снимается и ставится. Проста в уходе и обслуживании. Не занимает много места при хранении зимой.</p>
                                <p>от 3000 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki2} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антикошка</h4>
                                <p>Полотно крепится на плунжерное крепление и способно выдерживать вес среднего животного, которое может погнаться за мухой или солнечным зайчиком. Размер ячеек такой сетки 1 на 1 мм, а толщина 0,6 мм.</p>
                                <p>от 3500 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki3} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антипыльца</h4>
                                <p>Сетка «Антипыльца» — настоящая находка для людей, страдающих от аллергии. Минимальные размеры ячеек задерживают не только насекомых, но и пыльцу растений. Сетка надежно фиксируется в раме, которую можно поставить в отдельный профиль.</p>
                                <p>от 3500 руб/м2</p>
                            </div>

                        </div>

                    </div>
                </div>


                {/* Таблица цен */}
                <h3>Цены на Москитные Сетки</h3>
                <div className="scroll-table">
                    <table className="price-table">
                        <thead>
                            <tr>
                                <th>Количество, шт</th>
                                <th>Цена (руб.) с замером</th>
                                <th>Цена (руб.) самовывоз</th>
                            </tr>
                        </thead>
                        <tbody>
                            {[1, 2, 3, 4, 5, 6].map((num) => (
                                <tr key={num}>
                                    <td>{num}</td>
                                    <td>{num * 1800}</td>
                                    <td>{num * 1600}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>


                <h3>Как заказать москитную сетку</h3>
                <ul className="order-steps">

                    {/* Как заказать */}
                    <li>Выбрать тип сетки</li>
                    <li>Снять замеры или вызвать мастера</li>
                    <li>Связаться с нами: <b>+(965) 475-77-88</b></li>
                    <li>Оформить заказ и согласовать стоимость</li>
                </ul>


            </>
        ),
    },
    {
        id: 7, title: "Дверь плиссе",
        content: (
            <>
                {/* Условия выезда */}
                <div className="conditions">
                    <h3>Условия выезда</h3>
                    <p>✔️ Стоимость выезда мастера – <b>1 500 руб.</b> (бесплатно при заключении договора).</p>
                    <p>✔️ Минимальная сумма заказа – <b style={{ color: "red" }}>3 000 руб.</b></p>
                </div>


                {/* Описание и фото */}
                <div className="description">
                    <img src={Plise1} alt="Москитная сетка плисе" className="main-image" />
                    <p>
                        Производство, продажа и установка раздвижных сеток плиссе на дверь по доступным ценам. Замер, доставку и ремонт осуществляют наши специалисты из компании «Мастер окон». Доступны как одностворчатые или двустворчатые конструкции. Работаем в Краснодаре и Краснодарском крае.

                        <br /> • Стоимость от – 4000 рублей за квадратный метр.
                        <br />  • Гарантия от 1 года на все виды услуг.
                        <br />  • Замер в любой удобный для вас день.
                    </p>
                </div>


                <div className="description1">
                    <h4>
                        Особенности конструкции
                    </h4>

                    <div className="descriptoin1_section">
                        <h5>Москитная сетка плиссе на дверь</h5>
                        <p>Дверь-сетка Plisse от насекомых — это простая и эффективная дверь, широко используемая во всем мире для защиты от комаров, мух и других насекомых, а также для воздухообмена в жилых помещениях.

                            <br />     • Размеры: до 9 метров в длину и 3 метров в высоту. Мы подберем лучший метод установки, чтобы обеспечить идеальную посадку.

                            <br />     • Идеально подходит для пластиковых, деревянных или алюминиевых проемов входных дверей, балконов, веранд и беседок.

                            <br />     • Плиссе может быть оснащена ручкой с замком, а также сверхпрочной сеткой для защиты от домашних животных.

                            <br />     • Сетчатое складное полотно прочно закреплено раме. Если сетка повреждена, ее можно легко снять и заменить. Они работают по простой системе: скользят по направляющим и складываются, когда не используются.

                            <br />     • При производстве используются безопасные материалы и качественные комплектующие.
                        </p>



                        <img src={PliseSetka} alt="Рамочная сетка" className="plise-image" />
                    </div>



                </div>

                {/* Особенности, виды полотен, гарантии */}
                <div className="features">
                    <div className="feature-box">
                        <h4>Особенности</h4>
                        <p>✔ Дверная сетка Plisse открывается и закрывается по принципу "гармошки".<br />
                            Москитка "плиссе" свободно перемещается по направляющим, открывая и закрывая проем.</p>
                    </div>
                    <div className="feature-box">
                        <h4>Полотно</h4>
                        <p>✔Сетка "плиссе" комплектуется стандартным антимоскитным полотном:
                            <br /> •  размер ячейки 1,0*1,2 мм;
                            <br /> • защищает от насекомых, пыльцы и яркого света
                            <br /> • материал - стекловолокно
                            <br /> • цвет сетки - серый
                        </p>
                    </div>


                    <div className="feature-box">
                        <h4>Оплата и доставка</h4>
                        <p>✔ От 4000 руб/кв.м. <br />
                            Подробнее в прайс-листе ниже.</p>
                        <p>✔ Мы принимаем оплату любым удобным Вам способом. Вы можете заказать у нас доставку и установку москитной сетки на дом.</p>

                    </div>
                    <div className="feature-box">
                        <h4>Гарантии</h4>
                        <p>✔ Гарантия 1 год на все виды москитных сеток</p>
                    </div>



                </div>


                <div className="sectionPolotno">
                    <h4>Виды полотен</h4>
                    <div className="polotno">
                        <div className="polotno1">
                            <img src={setki} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Классическая</h4>
                                <p>Один из самых распространенных вариантов сеток для окон. Надежно защищает дом от насекомых. Легко снимается и ставится. Проста в уходе и обслуживании. Не занимает много места при хранении зимой.</p>
                                <p>от 2500 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki1} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антипыль</h4>
                                <p>Один из самых распространенных вариантов сеток для окон. Надежно защищает дом от насекомых. Легко снимается и ставится. Проста в уходе и обслуживании. Не занимает много места при хранении зимой.</p>
                                <p>от 3000 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki2} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антикошка</h4>
                                <p>Полотно крепится на плунжерное крепление и способно выдерживать вес среднего животного, которое может погнаться за мухой или солнечным зайчиком. Размер ячеек такой сетки 1 на 1 мм, а толщина 0,6 мм.</p>
                                <p>от 3500 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki3} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антипыльца</h4>
                                <p>Сетка «Антипыльца» — настоящая находка для людей, страдающих от аллергии. Минимальные размеры ячеек задерживают не только насекомых, но и пыльцу растений. Сетка надежно фиксируется в раме, которую можно поставить в отдельный профиль.</p>
                                <p>от 3500 руб/м2</p>
                            </div>

                        </div>

                    </div>
                </div>


                {/* Таблица цен */}
                <h3>Цены на Москитные Сетки</h3>
                <div className="scroll-table">
                    <table className="price-table">
                        <thead>
                            <tr>
                                <th>Количество, шт</th>
                                <th>Цена (руб.) с замером</th>
                                <th>Цена (руб.) самовывоз</th>
                            </tr>
                        </thead>
                        <tbody>
                            {[1, 2, 3, 4, 5, 6].map((num) => (
                                <tr key={num}>
                                    <td>{num}</td>
                                    <td>{num * 1800}</td>
                                    <td>{num * 1600}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>


                <h3>Как заказать москитную сетку</h3>
                <ul className="order-steps">

                    {/* Как заказать */}
                    <li>Выбрать тип сетки</li>
                    <li>Снять замеры или вызвать мастера</li>
                    <li>Связаться с нами: <b>+7 (965) 475-77-88</b></li>
                    <li>Оформить заказ и согласовать стоимость</li>
                </ul>


            </>
        ),
    },
    {
        id: 8,
        title: "Системы Алюм ПРО",
        content: (
            <>
                {/* Условия выезда */}
                <div className="conditions">
                    <h3>Условия выезда</h3>
                    <p>✔️ Стоимость выезда мастера – <b>1 500 руб.</b> (бесплатно при заключении договора).</p>
                    <p>✔️ Минимальная сумма заказа – <b style={{ color: "red" }}>3 000 руб.</b></p>
                </div>


                {/* Описание и фото */}
                <div className="description">
                    <img src={mosquitoImage} alt="Москитная сетка" className="main-image" />
                    <p>
                        В нашей компании Вы можете заказать москитные сетки Алюм ПРО для алюминиевых окон различной конструкции, устанавливаемые без сверления рамы. Система Алюм ПРО специально разрабатывалась для различных алюминиевых конструкций в том числе окон, витражей и фасадов.
                        Данный вид москитной сетки полностью исключает попадание внутрь помещения насекомых, птиц, тополиного пуха, а при выборе специальных полотен и пыльцы растений. Такая степень защиты достигается, в том числе, за счет щеточного уплотнителя по всему периметру прилегания сетки к алюминиевому окну.
                    </p>
                </div>


                <div className="description1">
                    <h4>
                        Особенности конструкции
                    </h4>

                    <div className="descriptoin1_section">
                        <h5>Технические особенности</h5>
                        <p>Москитные сетки Алюм ПРО имеют ряд преимуществ перед традиционными способами защиты от насекомых:
                            <br />     •  максимальная ширина защищаемого проема 1500 мм, максимальная высота до 3000 мм
                            <br />     • пропускают свет, что позволяет сохранить естественное освещение в помещении
                            <br />     • не создают помех для вентиляции воздуха, что важно для поддержания комфортной температуры в доме
                            <br />     • обладают высокой прочностью и долговечностью, что делает их идеальным выбором для длительного использования
                        </p>

                        <img src={PliseSetka} alt="Рамочная сетка" className="plise-image" />
                    </div>



                </div>

                {/* Особенности, виды полотен, гарантии */}
                <div className="features">
                    <div className="feature-box">
                        <h4>Особенности</h4>
                        <p>✔ Дверная сетка Plisse открывается и закрывается по принципу "гармошки".<br />
                            Москитка "плиссе" свободно перемещается по направляющим, открывая и закрывая проем.</p>
                    </div>
                    <div className="feature-box">
                        <h4>Полотно</h4>
                        <p>✔Сетка "плиссе" комплектуется стандартным антимоскитным полотном:
                            <br /> •  размер ячейки 1,0*1,2 мм;
                            <br /> • защищает от насекомых, пыльцы и яркого света
                            <br /> • материал - стекловолокно
                            <br /> • цвет сетки - серый
                        </p>
                    </div>


                    <div className="feature-box">
                        <h4>Оплата и доставка</h4>
                        <p>✔ От 4000 руб/кв.м. <br />
                            Подробнее в прайс-листе ниже.</p>
                        <p>✔ Мы принимаем оплату любым удобным Вам способом. Вы можете заказать у нас доставку и установку москитной сетки на дом.</p>

                    </div>
                    <div className="feature-box">
                        <h4>Гарантии</h4>
                        <p>✔ Гарантия 1 год на все виды москитных сеток</p>
                    </div>



                </div>


                <div className="sectionPolotno">
                    <h4>Виды полотен</h4>
                    <div className="polotno">
                        <div className="polotno1">
                            <img src={setki} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Классическая</h4>
                                <p>Один из самых распространенных вариантов сеток для окон. Надежно защищает дом от насекомых. Легко снимается и ставится. Проста в уходе и обслуживании. Не занимает много места при хранении зимой.</p>
                                <p>от 2500 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki1} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антипыль</h4>
                                <p>Один из самых распространенных вариантов сеток для окон. Надежно защищает дом от насекомых. Легко снимается и ставится. Проста в уходе и обслуживании. Не занимает много места при хранении зимой.</p>
                                <p>от 3000 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki2} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антикошка</h4>
                                <p>Полотно крепится на плунжерное крепление и способно выдерживать вес среднего животного, которое может погнаться за мухой или солнечным зайчиком. Размер ячеек такой сетки 1 на 1 мм, а толщина 0,6 мм.</p>
                                <p>от 3500 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki3} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антипыльца</h4>
                                <p>Сетка «Антипыльца» — настоящая находка для людей, страдающих от аллергии. Минимальные размеры ячеек задерживают не только насекомых, но и пыльцу растений. Сетка надежно фиксируется в раме, которую можно поставить в отдельный профиль.</p>
                                <p>от 3500 руб/м2</p>
                            </div>

                        </div>

                    </div>
                </div>


                {/* Таблица цен */}
                <h3>Цены на Москитные Сетки</h3>
                <div className="scroll-table">
                    <table className="price-table">
                        <thead>
                            <tr>
                                <th>Количество, шт</th>
                                <th>Цена (руб.) с замером</th>
                                <th>Цена (руб.) самовывоз</th>
                            </tr>
                        </thead>
                        <tbody>
                            {[1, 2, 3, 4, 5, 6].map((num) => (
                                <tr key={num}>
                                    <td>{num}</td>
                                    <td>{num * 1800}</td>
                                    <td>{num * 1600}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </div>

                <h3>Как заказать москитную сетку</h3>
                <ul className="order-steps">

                    {/* Как заказать */}
                    <li>Выбрать тип сетки</li>
                    <li>Снять замеры или вызвать мастера</li>
                    <li>Связаться с нами: <b>+(965) 475-77-88</b></li>
                    <li>Оформить заказ и согласовать стоимость</li>
                </ul>


            </>
        ),
    },
    {
        id: 9, title: "Антикошка",
        content: (
            <>
                {/* Условия выезда */}
                <div className="conditions">
                    <h3>Условия выезда</h3>
                    <p>✔️ Стоимость выезда мастера – <b>1 500 руб.</b> (бесплатно при заключении договора).</p>
                    <p>✔️ Минимальная сумма заказа – <b style={{ color: "red" }}>3 000 руб.</b></p>
                </div>


                {/* Описание и фото */}
                <div className="description">
                    <img src={mosquitoImage} alt="Москитная сетка" className="main-image" />
                    <p>
                        Компания "Мастер окон" предлагает недорого купить москитные сетки «Антикошка» для пластиковых окон, деревянных, алюминиевых. У нас производство осуществляется из надежных прочных материалов, что обеспечит Вашему домашнему питомцу полную безопасность. Гарантия на установленные нами сетки «Антикошка» составляет 1 год.
                    </p>
                </div>


                <div className="description1">
                    <h4>
                        Сверхпрочное полотно Pet Screen
                    </h4>

                    <div className="descriptoin1_section">

                        <p>Особую прочность и надежность использования сетки «Антикошка» придает специальное полотно от производителя Pet Screen. Сетка изготавливается из нитей полиэстера, покрытых пластиком. Прочность нитей обеспечивает также обработка специальным раствором. Многослойное ниточное волокно не рвется от кошачьих когтей, не поддается клюву крупных птиц.
                            Металлическая стека защищает от выпадения питомца, но представляет опасность когтям и лапам животного в случае образования заусенцев.
                            Варианты крепления сеток Антикошка:
                            <br />     •  Установка рамки на пластиковых уголках. Подходит для ПВХ окон нижних этажей дома. Такой способ крепления не защищает от выпадения животного, но предотвращает повреждение сетки когтями и зубами Вашего любимца.
                            <br />     • Сетка надежно удерживается в алюминиевом профиле с помощью уплотнительного шнура. Такое крепление не даст упасть даже самому активному котику.
                            <br />     • На деревянные окна и двери старого образца сетка «Антикошка» определяется и замеряется по месту установки.

                        </p>

                        <img src={Koshka} alt="Рамочная сетка" className="plise-image" />
                    </div>



                </div>

                {/* Особенности, виды полотен, гарантии */}
                <div className="features">
                    <div className="feature-box">
                        <h4>Особенности</h4>
                        <p>✔ Сетка Антикошка отличается повышенной прочностью. Она устойчива к воздействию когтей и зубов домашних животных и при необходимости защитит Вашего любимца от падения.</p>
                    </div>



                    <div className="feature-box">
                        <h4>Оплата и доставка</h4>
                        <p>✔ От 2000 руб/кв.м. <br />
                            Подробнее в прайс-листе ниже.</p>
                        <p>✔ Мы принимаем оплату любым удобным Вам способом. Вы можете заказать у нас доставку и установку москитной сетки на дом.</p>

                    </div>
                    <div className="feature-box">
                        <h4>Гарантии</h4>
                        <p>✔ Гарантия 1 год на все виды москитных сеток</p>
                    </div>



                </div>


                <div className="sectionPolotno">
                    <h4>Виды полотен</h4>
                    <div className="polotno">
                        <div className="polotno1">
                            <img src={setki} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Классическая</h4>
                                <p>Один из самых распространенных вариантов сеток для окон. Надежно защищает дом от насекомых. Легко снимается и ставится. Проста в уходе и обслуживании. Не занимает много места при хранении зимой.</p>
                                <p>от 2500 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki1} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антипыль</h4>
                                <p>Один из самых распространенных вариантов сеток для окон. Надежно защищает дом от насекомых. Легко снимается и ставится. Проста в уходе и обслуживании. Не занимает много места при хранении зимой.</p>
                                <p>от 3000 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki2} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антикошка</h4>
                                <p>Полотно крепится на плунжерное крепление и способно выдерживать вес среднего животного, которое может погнаться за мухой или солнечным зайчиком. Размер ячеек такой сетки 1 на 1 мм, а толщина 0,6 мм.</p>
                                <p>от 3500 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki3} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антипыльца</h4>
                                <p>Сетка «Антипыльца» — настоящая находка для людей, страдающих от аллергии. Минимальные размеры ячеек задерживают не только насекомых, но и пыльцу растений. Сетка надежно фиксируется в раме, которую можно поставить в отдельный профиль.</p>
                                <p>от 3500 руб/м2</p>
                            </div>

                        </div>

                    </div>
                </div>


                {/* Таблица цен */}
                <h3>Цены на Москитные Сетки</h3>
                <div className="scroll-table">
                    <table className="price-table">
                        <thead>
                            <tr>
                                <th>Количество, шт</th>
                                <th>Цена (руб.) с замером</th>
                                <th>Цена (руб.) самовывоз</th>
                            </tr>
                        </thead>
                        <tbody>
                            {[1, 2, 3, 4, 5, 6].map((num) => (
                                <tr key={num}>
                                    <td>{num}</td>
                                    <td>{num * 1800}</td>
                                    <td>{num * 1600}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>


                <h3>Как заказать москитную сетку</h3>
                <ul className="order-steps">

                    {/* Как заказать */}
                    <li>Выбрать тип сетки</li>
                    <li>Снять замеры или вызвать мастера</li>
                    <li>Связаться с нами: <b>+(965) 475-77-88</b></li>
                    <li>Оформить заказ и согласовать стоимость</li>
                </ul>


            </>
        ),
    },
    {
        id: 10, title: "Антипыль",
        content: (
            <>
                {/* Условия выезда */}
                <div className="conditions">
                    <h3>Условия выезда</h3>
                    <p>✔️ Стоимость выезда мастера – <b>1 500 руб.</b> (бесплатно при заключении договора).</p>
                    <p>✔️ Минимальная сумма заказа – <b style={{ color: "red" }}>3 000 руб.</b></p>
                </div>


                {/* Описание и фото */}
                <div className="description">
                    <img src={mosquitoImage} alt="Москитная сетка" className="main-image" />
                    <p>
                        Живете вблизи автострады? Ваша квартира находится на первом этаже, и уличная пыль попадает в дом через окна? Решением этих и многих других подобных проблем стало изобретение москитной сетки «Антипыль».
                    </p>
                </div>


                <div className="description1">
                    <h4>
                        Преимущества сетки Антипыль
                    </h4>

                    <div className="descriptoin1_section">

                        <p>Особенности сетки Micro-mesh
                            Для производства противомоскитного сеточного полотна Micro-mesh используется фибергласс и стекловолокно. Это придает особую прочность изделию. Сетка изготавливается с размером ячеек 0,25х1 мм, что является преградой на пути мелкой пыли и уличного мусора. Из-за небольших размеров ячеек сетка быстрее загрязняется. Однако изделие легко промывается струей теплой воды.

                            Плотной сетки не позволяет пропускать внутрь помещения большое количество солнечного света, поэтому данный оконный аксессуар редко устанавливают в темных комнатах.

                            Полотно надежно крепится к профилю, который может быть установлен в оконный проем. Также выпускаются и другие разновидности конструкции сетки «Антипыль» – роллетные, раздвижные. Изделие можно использовать для проемов балконных блоков, межкомнатных дверей, окон.
                            <br />     •  универсальность – для установки подходит любая конструкция рамы;
                            <br />     •  максимальная защита от уличной пыли и насекомых;
                            <br />     •  простота ухода;
                            <br />     •  привлекательный внешний вид окна;
                            <br />     •  эффективность эксплуатации благодаря материалу Micro-mesh (США).

                        </p>

                        <img src={Antipil} alt="Рамочная сетка" className="plise-image" />
                    </div>



                </div>

                {/* Особенности, виды полотен, гарантии */}
                <div className="features">
                    <div className="feature-box">
                        <h4>Особенности</h4>
                        <p>✔ Сетка Антикошка отличается повышенной прочностью. Она устойчива к воздействию когтей и зубов домашних животных и при необходимости защитит Вашего любимца от падения.</p>
                    </div>
                    <div className="feature-box">
                        <h4>Полотно</h4>
                        <p>✔Сетка "плиссе" комплектуется стандартным антимоскитным полотном:
                            <br /> •  размер ячейки 1,0*1,2 мм;
                            <br /> • защищает от насекомых, пыльцы и яркого света
                            <br /> • материал - стекловолокно
                            <br /> • цвет сетки - серый
                        </p>
                    </div>


                    <div className="feature-box">
                        <h4>Оплата и доставка</h4>
                        <p>✔ От 2000 руб/кв.м. <br />
                            Подробнее в прайс-листе ниже.</p>
                        <p>✔ Мы принимаем оплату любым удобным Вам способом. Вы можете заказать у нас доставку и установку москитной сетки на дом.</p>

                    </div>
                    <div className="feature-box">
                        <h4>Гарантии</h4>
                        <p>✔ Гарантия 1 год на все виды москитных сеток</p>
                    </div>



                </div>


                <div className="sectionPolotno">
                    <h4>Виды полотен</h4>
                    <div className="polotno">
                        <div className="polotno1">
                            <img src={setki} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Классическая</h4>
                                <p>Один из самых распространенных вариантов сеток для окон. Надежно защищает дом от насекомых. Легко снимается и ставится. Проста в уходе и обслуживании. Не занимает много места при хранении зимой.</p>
                                <p>от 2500 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki1} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антипыль</h4>
                                <p>Один из самых распространенных вариантов сеток для окон. Надежно защищает дом от насекомых. Легко снимается и ставится. Проста в уходе и обслуживании. Не занимает много места при хранении зимой.</p>
                                <p>от 3000 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki2} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антикошка</h4>
                                <p>Полотно крепится на плунжерное крепление и способно выдерживать вес среднего животного, которое может погнаться за мухой или солнечным зайчиком. Размер ячеек такой сетки 1 на 1 мм, а толщина 0,6 мм.</p>
                                <p>от 3500 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki3} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антипыльца</h4>
                                <p>Сетка «Антипыльца» — настоящая находка для людей, страдающих от аллергии. Минимальные размеры ячеек задерживают не только насекомых, но и пыльцу растений. Сетка надежно фиксируется в раме, которую можно поставить в отдельный профиль.</p>
                                <p>от 3500 руб/м2</p>
                            </div>

                        </div>

                    </div>
                </div>


                {/* Таблица цен */}
                <h3>Цены на Москитные Сетки</h3>
                <div className="scroll-table">
                    <table className="price-table">
                        <thead>
                            <tr>
                                <th>Количество, шт</th>
                                <th>Цена (руб.) с замером</th>
                                <th>Цена (руб.) самовывоз</th>
                            </tr>
                        </thead>
                        <tbody>
                            {[1, 2, 3, 4, 5, 6].map((num) => (
                                <tr key={num}>
                                    <td>{num}</td>
                                    <td>{num * 1800}</td>
                                    <td>{num * 1600}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </div>

                <h3>Как заказать москитную сетку</h3>
                <ul className="order-steps">

                    {/* Как заказать */}
                    <li>Выбрать тип сетки</li>
                    <li>Снять замеры или вызвать мастера</li>
                    <li>Связаться с нами: <b>+(965) 475-77-88</b></li>
                    <li>Оформить заказ и согласовать стоимость</li>
                </ul>


            </>
        ),
    },
    {
        id: 11, title: "Антипыльца",
        content: (
            <>
                {/* Условия выезда */}
                <div className="conditions">
                    <h3>Условия выезда</h3>
                    <p>✔️ Стоимость выезда мастера – <b>1 500 руб.</b> (бесплатно при заключении договора).</p>
                    <p>✔️ Минимальная сумма заказа – <b style={{ color: "red" }}>3 000 руб.</b></p>
                </div>





                <div className="description1">
                    <h4>
                        Особенности полотна Антипыльца
                    </h4>

                    <div className="descriptoin1_section">

                        <p>Полотно Poll-Tex – это специальная мелкоячеистая антиаллергенная сетка. Размеры ее ячеек – 0,2*0,9 мм, что в несколько раз меньше, чем у стандартного полотна.
                            Сетка Poll-Tex защищает комнату от:
                            Особый защитный эффект достигается путем особого расположения нейлоновых нитей полотна: на стандартных сетках ячейки квадратные, а в Антипыльце - прямоугольные. Благодаря этому обеспечивается лучшая фильтрация.
                            <br />     •   насекомых и мошкары;
                            <br />     •   тополиного пуха;
                            <br />     •   пыльцы растений.

                        </p>

                        <img src={Antipilca} alt="Рамочная сетка" className="plise-image" />
                    </div>



                </div>

                {/* Особенности, виды полотен, гарантии */}
                <div className="features">
                    <div className="feature-box">
                        <h4>Особенности</h4>
                        <p>✔ Москитная сетка Антипыльца отличается уменьшенным размером ячеек - 0,2*0,9 мм. Это самый маленький размер ячеек, они задерживают распространение самых мелких частиц.</p>
                    </div>



                    <div className="feature-box">
                        <h4>Оплата и доставка</h4>
                        <p>✔ От 2000 руб/кв.м. <br />
                            Подробнее в прайс-листе ниже.</p>
                        <p>✔ Мы принимаем оплату любым удобным Вам способом. Вы можете заказать у нас доставку и установку москитной сетки на дом.</p>

                    </div>
                    <div className="feature-box">
                        <h4>Гарантии</h4>
                        <p>✔ Гарантия 1 год на все виды москитных сеток</p>
                    </div>



                </div>


                <div className="sectionPolotno">
                    <h4>Виды полотен</h4>
                    <div className="polotno">
                        <div className="polotno1">
                            <img src={setki} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Классическая</h4>
                                <p>Один из самых распространенных вариантов сеток для окон. Надежно защищает дом от насекомых. Легко снимается и ставится. Проста в уходе и обслуживании. Не занимает много места при хранении зимой.</p>
                                <p>от 2500 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki1} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антипыль</h4>
                                <p>Один из самых распространенных вариантов сеток для окон. Надежно защищает дом от насекомых. Легко снимается и ставится. Проста в уходе и обслуживании. Не занимает много места при хранении зимой.</p>
                                <p>от 3000 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki2} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антикошка</h4>
                                <p>Полотно крепится на плунжерное крепление и способно выдерживать вес среднего животного, которое может погнаться за мухой или солнечным зайчиком. Размер ячеек такой сетки 1 на 1 мм, а толщина 0,6 мм.</p>
                                <p>от 3500 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki3} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антипыльца</h4>
                                <p>Сетка «Антипыльца» — настоящая находка для людей, страдающих от аллергии. Минимальные размеры ячеек задерживают не только насекомых, но и пыльцу растений. Сетка надежно фиксируется в раме, которую можно поставить в отдельный профиль.</p>
                                <p>от 3500 руб/м2</p>
                            </div>

                        </div>

                    </div>
                </div>


                {/* Таблица цен */}
                <h3>Цены на Москитные Сетки</h3>
                <div className="scroll-table">
                    <table className="price-table">
                        <thead>
                            <tr>
                                <th>Количество, шт</th>
                                <th>Цена (руб.) с замером</th>
                                <th>Цена (руб.) самовывоз</th>
                            </tr>
                        </thead>
                        <tbody>
                            {[1, 2, 3, 4, 5, 6].map((num) => (
                                <tr key={num}>
                                    <td>{num}</td>
                                    <td>{num * 1800}</td>
                                    <td>{num * 1600}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>


                <h3>Как заказать москитную сетку</h3>
                <ul className="order-steps">

                    {/* Как заказать */}
                    <li>Выбрать тип сетки</li>
                    <li>Снять замеры или вызвать мастера</li>
                    <li>Связаться с нами: <b>+(965) 475-77-88</b></li>
                    <li>Оформить заказ и согласовать стоимость</li>
                </ul>


            </>
        ),
    },
    {
        id: 12, title: "Прозрачные (Ultravue)",
        content: (
            <>
                {/* Условия выезда */}
                <div className="conditions">
                    <h3>Условия выезда</h3>
                    <p>✔️ Стоимость выезда мастера – <b>1 500 руб.</b> (бесплатно при заключении договора).</p>
                    <p>✔️ Минимальная сумма заказа – <b style={{ color: "red" }}>3 000 руб.</b></p>
                </div>





                <div className="description1">
                    <h4>
                        Что такое сетки Ultravue
                    </h4>

                    <div className="descriptoin1_section">

                        <p>Антимоскитные сетки Ultravue (Ультравью, или как их еще называют - Maxi Vision, Макси Вижн) изготавливаются из более тонкой нити. Благодаря этому такая сетка имеет более привлекательный внешний вид, улучшает обзор и пропускает больше света и воздуха.
                            <br />Сетка Ультравью – на 25% больше обзора, света и воздуха.
                            <br />  При этом прочность такой сетки ничем не отличается от стандартного полотна.
                            <br /> Конечно, не стоит ожидать, что такая сетка совсем незаметна на окне и абсолютно прозрачна. Нет, это не так, и продавцы, которые уверяют вас в этом, не совсем честны. Сетка Ультравью выделяется на однородном фоне гораздо меньше, чем обычная, но не настолько, чтобы ее не заметить.

                            <br />  • Характеристики москитных сеток Ultravue
                            <br /> • Материал: стекловолокно с защитных ПВХ-покрытием.
                            <br /> • Размер ячейки: 1,0*1,0 мм.
                            <br /> • Цвет: темно-серый, черный.
                        </p>

                        <img src={Ultra} alt="Рамочная сетка" className="plise-image" />
                    </div>



                </div>

                {/* Особенности, виды полотен, гарантии */}
                <div className="features">
                    <div className="feature-box">
                        <h4>Особенности</h4>
                        <p>✔ Москитная сетка Ultravue изготавливается из более тонких нитей.

                            Благодаря этому сетка Ультравью пропускает на 25% больше света и воздуха и обеспечивает лучший обзор.</p>
                    </div>

                    <div className="feature-box">
                        <h4>Конструкция сетки</h4>
                        <p>✔  Сетка с полотном Ультравью может быть двух видов:<br />
                            • рамочная (вставная),
                            • рамочная на плунжерах.
                        </p>
                    </div>




                    <div className="feature-box">
                        <h4>Оплата и доставка</h4>
                        <p>✔ От 2000 руб/кв.м. <br />
                            Подробнее в прайс-листе ниже.</p>
                        <p>✔ Мы принимаем оплату любым удобным Вам способом. Вы можете заказать у нас доставку и установку москитной сетки на дом.</p>

                    </div>
                    <div className="feature-box">
                        <h4>Гарантии</h4>
                        <p>✔ Гарантия 1 год на все виды москитных сеток</p>
                    </div>



                </div>


                {/* <div className="sectionPolotno">
                    <h4>Виды полотен</h4>
                    <div className="polotno">
                        <div className="polotno1">
                            <img src={setki} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Классическая</h4>
                                <p>Один из самых распространенных вариантов сеток для окон. Надежно защищает дом от насекомых. Легко снимается и ставится. Проста в уходе и обслуживании. Не занимает много места при хранении зимой.</p>
                                <p>от 2500 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki1} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антипыль</h4>
                                <p>Один из самых распространенных вариантов сеток для окон. Надежно защищает дом от насекомых. Легко снимается и ставится. Проста в уходе и обслуживании. Не занимает много места при хранении зимой.</p>
                                <p>от 3000 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki2} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антикошка</h4>
                                <p>Полотно крепится на плунжерное крепление и способно выдерживать вес среднего животного, которое может погнаться за мухой или солнечным зайчиком. Размер ячеек такой сетки 1 на 1 мм, а толщина 0,6 мм.</p>
                                <p>от 3500 руб/м2</p>
                            </div>
                        </div>
                        <div className="polotno1">
                            <img src={setki3} alt="Рамочная сетка" className="main-image" />
                            <div className="container">
                                <h4>✔ Антипыльца</h4>
                                <p>Сетка «Антипыльца» — настоящая находка для людей, страдающих от аллергии. Минимальные размеры ячеек задерживают не только насекомых, но и пыльцу растений. Сетка надежно фиксируется в раме, которую можно поставить в отдельный профиль.</p>
                                <p>от 3500 руб/м2</p>
                            </div>

                        </div>

                    </div>
                </div> */}


                {/* Таблица цен */}
                <h3>Цены на Москитные Сетки</h3>
                <div className="scroll-table">
                    <table className="price-table">
                        <thead>
                            <tr>
                                <th>Количество, шт</th>
                                <th>Цена (руб.) с замером</th>
                                <th>Цена (руб.) самовывоз</th>
                            </tr>
                        </thead>
                        <tbody>
                            {[1, 2, 3, 4, 5, 6].map((num) => (
                                <tr key={num}>
                                    <td>{num}</td>
                                    <td>{num * 1800}</td>
                                    <td>{num * 1600}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </div>

                <h3>Как заказать москитную сетку</h3>
                <ul className="order-steps">

                    {/* Как заказать */}
                    <li>Выбрать тип сетки</li>
                    <li>Снять замеры или вызвать мастера</li>
                    <li>Связаться с нами: <b>+(965) 475-77-88</b></li>
                    <li>Оформить заказ и согласовать стоимость</li>
                </ul>


            </>
        ),
    },




];

const MosquitoNets = forwardRef((props, ref) => {
    const [activeCategory, setActiveCategory] = useState(categories[0]);

    return (
        <div ref={ref} className="mosquito-nets" >
            <h2>МОСКИТНЫЕ СЕТКИ</h2>
            <div className="content">
                {/* Левый блок - Список категорий */}
                <div className="menu">
                    {categories.map((cat) => (
                        <div
                            key={cat.id}
                            className={`menu-item ${activeCategory.id === cat.id ? "active" : ""}`}
                            onClick={() => setActiveCategory(cat)}
                        >
                            {cat.title}
                        </div>
                    ))}
                </div>

                {/* Правый блок - Контент выбранной категории */}
                <div className="display">{activeCategory.content}</div>
            </div>
        </div>
    );
});

export default MosquitoNets;
